@import '~antd/dist/antd.css';
@import './pages/CRM/ApplicationViewTable.scss';

.App .logo {
    padding: 16px;
    padding-left: 24px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
}

.ant-table-thead>tr>th {
    border-right: 1px solid #f0f0f0;
}

.clickable-row {
    cursor: pointer;
}

.clickable-row td {
    vertical-align: top;
}

.student-payments-row-plus {
    background-color: #d9f7be;
}

.student-payments-row-promo {
    background-color: #f7f3be;
}

.student-payments-row-minus {
    background-color: #ffccc7;
}

.student-payments-row-credit-paid {
    background-color: #c0c0c0;
}

.student-payments-row-credit {
    background-color: #ffca8e;
}

.ant-layout-sider {
    background: #1e1e24 !important;
}

.ant-menu-dark {
    background: #1e1e24 !important;
}

.ant-menu-item {
    border-left: 4px solid transparent;
}

.ant-menu-item-selected {
    background: #282a31 !important;
    border-left: 4px solid #0094c4;
}

.ant-upload-list-picture-card-container {
    width: 300px;
    height: 300px;
}

.service-cb .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
}

.add-services-forms .ant-form-item {
    margin-bottom: 0;
}

.add-services-forms .service-card {
    margin: 15px 0;
}

.add-services-forms .service-cb label {
    text-align: center;
}

.CSDD-card .ant-form-item {
    margin-bottom: 5px;
}

.student-services-form .ant-form-item {
    margin: 0 30px;
}

.student-edit-header .ant-page-header-heading-left {
    width: 80%;
}

.checkpoints-card .check-item .ant-card-body {
    display: flex;
    align-items: center;
}

.selected-row {
    background-color: rgb(144, 238, 144);
}

.change-lesson-group .ant-table-tbody>tr.ant-table-row:hover>td {
    background: unset;
}

.crm-card .missed-reminder td {
    background-color: rgb(228, 186, 186);
}

.active-link button {
    /* font-weight: bold; */
    border-color: #000;
    background-color: #e9e9e9
}

@media print {
    .ant-layout-sider {
        display: none;
    }

    @page {
        size: 210mm 297mm;
        margin: 2cm
    }

    .printable {
        // padding: 60px;
    }

    .ant-page-header {
        display: none;
    }

    th,
    td {
        padding: 5px !important;
    }

    .ant-layout {
        display: block;
        margin-bottom: 100px;
        background: white;
    }

    .print-stuff {
        padding-top: 100px;
    }
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);

    /* Set vertical boundaries for the document editor. */
    max-height: 700px;

    /* This element is a flex container for easier rendering. */
    display: flex;
    flex-flow: column nowrap;
}

.document-editor__toolbar {
    /* Make sure the toolbar container is always above the editable. */
    z-index: 1;

    /* Create the illusion of the toolbar floating over the editable. */
    box-shadow: 0 0 5px hsla(0, 0%, 0%, .2);

    /* Use the CKEditor CSS variables to keep the UI consistent. */
    border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside the container. */
.document-editor__toolbar .ck-toolbar {
    border: 0;
    border-radius: 0;
}

/* Make the editable container look like the inside of a native word processor application. */
.document-editor__editable-container {
    padding: calc(2 * var(--ck-spacing-large));
    background: var(--ck-color-base-foreground);

    /* Make it possible to scroll the "page" of the edited content. */
    overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
    /* Set the dimensions of the "page". */
    width: 15.8cm;
    min-height: 21cm;

    /* Keep the "page" off the boundaries of the container. */
    padding: 1cm 2cm 2cm;

    border: 1px hsl(0, 0%, 82.7%) solid;
    border-radius: var(--ck-border-radius);
    background: white;

    /* The "page" should cast a slight shadow (3D illusion). */
    box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

    /* Center the "page". */
    margin: 0 auto;
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.banner .main-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.banner .img-container {
    width: 100%;
    overflow: hidden;
}

.banner .img-container img {
    width: 100%;
}

.banner .content {
    width: 100%;
}

@media screen and (min-width: 728px) {
    .banner .img-container {
        width: 45%;
        border-radius: 8px;
    }

    .banner .img-container img {
        height: 100%;
        width: auto;
        border-radius: 8px;
    }

    .banner .content {
        width: 55%;
    }
}

.banner .content-wrapper {
    padding: 20px 24px 24px;
}

.banner h1 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 21px;
    line-height: 28px;
    letter-spacing: -0.4px;
}

.banner h2 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.2px;
}

.banner p {
    margin: 0;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: #5c5f70;
}

.banner ul {
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
    list-style-type: none;
}

.banner li {
    min-height: 23px;
    align-items: center;
    margin-bottom: 4px;
    margin-left: 27px;
    position: relative;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.2px;
    font-weight: 600;
}

.banner li:before {
    content: ' ';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 2px;
    left: -27px;
    /* background-color: red; */
    background-image: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM15.7071 7.70711C16.0976 7.31658 16.0976 6.68342 15.7071 6.29289C15.3166 5.90237 14.6834 5.90237 14.2929 6.29289L9 11.5858L7.70711 10.2929C7.31658 9.90237 6.68342 9.90237 6.29289 10.2929C5.90237 10.6834 5.90237 11.3166 6.29289 11.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.7071 7.70711Z' fill='%2329A370'></path></svg>");
}

.ant-btn.excel-btn {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.excel-btn__logo {
    width: 30px;
    height: 30px;
}

.deposit-table .ant-table-title,
.deposit-table .ant-table-footer {
    background-color: #f0f0f0;
    border: unset !important;
}

.deposit-table .ant-table-footer b {
    margin: 5px;
    text-align: end;
}

.agrement-wrap-box {
    width: 100%;
}

.agrement-wrap-box * {
    white-space: normal;
}

.balance-mini-table th,
.balance-mini-table td {
    border: 1px solid #f0f0f0;
    padding: 8px;
}

.bonus-payment-driving th,
.bonus-payment-driving td {
    background-color: #ffca8e;
}

.balance-mini-table th {
    background-color: #c0c0c0;
}

.outer-upper-cell,
.outer-bottom-cell {
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 40px;
    border: 1px solid silver;
    background: #FAFAFA;
    padding: 16px;
    line-height: 8px;
}

.outer-bottom-cell {
    top: unset;
    bottom: -40px;
}

.elearning-help-settings-card {

    .video-upload-btn {
        margin-left: 15px;
    }
}

.video-part-upload-container {

    &-empty:hover {
        box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
        cursor: pointer;
    }
}

#resumable-instructions-video-input {

    &-lv,
    &-en,
    &-ru {
        padding: 8px 16px;
        border: 1px solid #aaaaaa;
        border-radius: 4px;

        &:hover {
            cursor: pointer;
            border-color: #1890FF;
            color: #1890FF;
        }
    }

}

.instructions-video-preview-player {
    margin-top: 15px;
}

.question-img-wrap {
    width: 300px;
    height: 200px;
    margin-bottom: 10px;

    img {
        // width: 100%;
        height: 100%;
    }
}

.in-debt {
    background: #ff9e9e;
}

.cleared-credit {
    background: #abffab;
}

.theory-exam-tabs {
    overflow: unset !important;

    .ant-tabs-nav-wrap {
        background-color: #FFFFFF;
        padding: 0 24px;
    }
}

/* Chrome, Safari, Edge, Opera */
input.without-number-arrows::-webkit-outer-spin-button,
input.without-number-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number].without-number-arrows {
    -moz-appearance: textfield;
}

.read-whatsapp-row {
    background-color: rgba(150, 255, 182, 0.452);
}

.exam-question-card {
    height: 80vh;
    border: 1px solid green;
    padding: 20px;
    margin-bottom: 20px;

    &.fault {
        border-color: red;
    }

    @media screen and (max-width: 768px) {
        height: unset;
    }

    .question-big-text {
        font-size: 24px;
        line-height: 34px;
        color: #252529;
        // font-weight: bolder;
        margin-bottom: 30px;
        display: block;
        width: 100%;
    }

    .img-wrap {
        width: 100%;
        margin-bottom: 10px;

        // margin: 0 0 20px 16px;
        &.question-img {
            height: 300px;

            // overflow: hidden;
            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
    }

    .description-wrap {
        padding: 10px;
        border: 1px solid #cccccc;
    }

    .answer {
        display: flex;
        background-color: #F0F2F5;
        flex-wrap: wrap;
        width: 100%;
        padding: 17px 20px;
        margin-bottom: 4px;
        border-radius: 2px;

        &.selected {
            background-color: #f7f7f7;
            box-shadow: 0 0 11px rgba(33, 33, 33, .2);
        }

        &.correct {
            background-color: #FFFFFF;
            border: 2px solid #2C9C3B;
        }

        &.incorrect {
            background-color: #FFFFFF;
            border: 2px solid #CC3326;
        }

        .answer-col {
            width: 90%;

            .img-wrap {
                // display: flex;
                // justify-content: center;
                // border: 1px solid black;
                height: 200px;
                width: 100%;
                margin: 0 0 10px 0;
                padding: 3px;
                overflow: hidden;
                // background-color: #FFFFFF;

                img {
                    // width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            p {
                color: #000000;
                font-weight: bold;
                font-size: 14px;
                margin: 0;
            }
        }

    }

    .warning {
        display: block;
        margin: 16px 0;
        font-size: 20px;
        line-height: 26px;

        &.red {
            color: #CC3326;
        }

        &.green {
            color: #2C9C3B
        }
    }

    .btn-submit {
        width: 100%;
        height: unset;
        padding: 16px 20px;
        margin-top: 20px;
        background-color: #059DC1;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        border: unset;
        border-radius: 2px;
        cursor: pointer;

        &:hover {
            background-color: darken($color: #059DC1, $amount: 2);
        }

        &.disabled {
            background-color: #ABAEB4;
            color: #FFFFFF;
            cursor: not-allowed;

            &:hover {
                background-color: #ABAEB4;
            }
        }
    }
}

.urgent {
    background-color: rgb(255 223 223);
}

.red-row {
    color: red;
}

.problem-modules-list {
    li:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.strange-color-status-select {
    .ant-select-selector {
        background-color: transparent !important;
    }
}